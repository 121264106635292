import React from "react";
import { useEffect } from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Sidebar from "./Layout/Sidebar";
import styled from "styled-components";
import { Bar, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import moment from "moment/moment";
import { useState } from "react";
import ReactLoading from "react-loading";
import { useBetween } from "use-between";
import useShareableState from "../Common/useShareableState";
import { getModules } from "../api/auth";

Chart.register(...registerables);

const websiteCardData = [
  {
    id: 0,
    name: "Total views/ events",
    color: "orange",
    count: 38576,
    description: "Total views till date",
  },
  {
    id: 1,
    name: "Total users",
    color: "lightseagreen",
    count: 4500,
    description: "Total views till date",
  },
  {
    id: 2,
    name: "Pages per user",
    color: "#E92E26",
    count: 21473,
    description: "Total views till date",
  },
  {
    id: 3,
    name: "Avg. Time spent",
    color: "royalblue",
    count: 4,
    description: "Total time spent",
  },
];

const ecommerceCardData = [
  {
    id: 0,
    name: "Total orders",
    color: "orange",
    description: "Total orders till date",
  },
  {
    id: 1,
    name: "Total customers",
    color: "lightseagreen",
    description: "Total customers till date",
  },
  {
    id: 2,
    name: "Avg. Order Size",
    color: "#E92E26",
    description: "Total views till date",
  },
  {
    id: 3,
    name: "Total published products",
    color: "royalblue",
    description: "Total published products",
  },
];
const CountStyles = styled.div`
  color: ${(props) => props.color};
  font-size: 26px;
  font-weight: 600;
`;

const AnalyticsCard = styled.div`
  background: #ffffff;
  padding: 6px 10px 10px 20px;
  min-width: 20%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
  border-radius: 5px;
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    left: 0px;
    top: 20%;
    height: 65%;
    border-radius: 20px;
    margin-left: -3px;
    border-left: 8px solid ${(props) => props.color};
  }
`;

function Analytics() {
  const [ecommerceAnalyticsData, setEcommerceAnalyticsData] = useState();
  const websiteAnalyticsData = {
    "totalEvents": 272,
    "totalUsers": 272,
    "pagesPerUser": 1.99,
    "avgTimeSpent": 5.7,
    "viewsData": [
        {
            "id": 1,
            "date": "Jun 01 2024",
            "value": "58"
        },
        {
            "id": 2,
            "date": "Jun 02 2024",
            "value": "64"
        },
        {
            "id": 3,
            "date": "Jun 03 2024",
            "value": "83"
        },
        {
            "id": 4,
            "date": "Jun 04 2024",
            "value": "67"
        }
    ],
    "browserUsed": [
        {
            "id": 1,
            "browser": "Chrome",
            "value": "218"
        },
        {
            "id": 2,
            "browser": "Safari",
            "value": "17"
        },
        {
            "id": 3,
            "browser": "Edge",
            "value": "7"
        },
        {
            "id": 4,
            "browser": "Android Webview",
            "value": "6"
        },
        {
            "id": 5,
            "browser": "Samsung Internet",
            "value": "6"
        },
        {
            "id": 6,
            "browser": "Firefox",
            "value": "4"
        },
        {
            "id": 7,
            "browser": "Safari (in-app)",
            "value": "2"
        },
        {
            "id": 8,
            "browser": "UC Browser",
            "value": "2"
        }
    ]
}
  const [trafficAnalyticsData, setTrafficAnalyticsData] = useState();
  const [ecommerceData, setEcommerceData] = useState(ecommerceCardData);
  const [websiteData, setWebsiteData] = useState(websiteCardData);
  const [loading, setLoading] = useState(true);
  const {setModulesList } = useBetween(useShareableState);

  useEffect(() => {
    document.title = "Analytics - YKZini";
  });

  const getModuleDetails = async () => {
    let res = await getModules();
    if (res?.status) {
      setModulesList(res?.payload);
    }
  };

  useEffect(() => {
    if(localStorage.getItem('token')){
      getModuleDetails();
    }
  }, [])

  const options = {
    responsive: true,
    aspectRatio: 3,
    backgroundColor: "green",
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 16,
          },
        },
      },
    },
  };

  useEffect(() => {
  
  }, []);

  useEffect(() => {
    const data = ecommerceData.map((object) => {
      return {
        ...object,
        dataCount:
          object.id === 0
            ? ecommerceAnalyticsData?.totalOrders
            : object.id === 1
            ? ecommerceAnalyticsData?.totalCustomers
            : object.id === 2
            ? ecommerceAnalyticsData?.avgOrderSize
            : ecommerceAnalyticsData?.totalPublishedProducts,
      };
    });

    const webData = websiteData.map((object) => {
      return {
        ...object,
        dataCount:
          object.id === 0
            ? websiteAnalyticsData?.totalEvents
            : object.id === 1
            ? websiteAnalyticsData?.totalUsers
            : object.id === 2
            ? websiteAnalyticsData?.pagesPerUser
            : websiteAnalyticsData?.avgTimeSpent,
      };
    });

    setEcommerceData(data);
    setWebsiteData(webData);
  }, [ecommerceAnalyticsData, websiteAnalyticsData]);

  var labels = [];
  var today = new Date();
  for (var i = 0; i < 16; i++) {
    var date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - i * 2
    );
    labels.push(moment(date).format("MMM DD YYYY"));
  }
  labels.push(0);

  const websiteGraphData = {
    labels: [
      0,
      websiteAnalyticsData?.viewsData?.map((item) => item.date),
    ].flat(),
    datasets: [
      {
        label: "Total views",
        data: [
          0,
          websiteAnalyticsData?.viewsData?.map((item) => Number(item.value)),
        ].flat(),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const trafficAnalyticsGraphData = {
    labels: [
      0,
      trafficAnalyticsData?.map((item) => item.source),
    ].flat(),
    datasets: [
      {
        label: "Total views",
        data: [
          0,
          trafficAnalyticsData?.map((item) => Number(item.value)),
        ].flat(),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  
  const ecommerceGraphData = {
    labels: [
      0,
      ecommerceAnalyticsData?.orderData?.map((item) => item.date),
    ].flat(),
    datasets: [
      {
        label: "Total orders",
        data: [
          0,
          ecommerceAnalyticsData?.orderData?.map((item) => item.noOfOrders),
        ].flat(),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row text-center">
                <div className="col-md-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Analytics</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a>Home</a>
                        </li>
                        <li className="breadcrumb-item active">Analytics</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Website Analytics */}
            {loading ? (
              <div className="page-loading">
                <ReactLoading
                  type={"spin"}
                  color={"#E92E26"}
                  height={"50px"}
                  width={"50px"}
                />
              </div>
            ) : (
              <>
                <div style={{ padding: "0 20px" }}>
                  <h2>Website Analytics</h2>
                  <div className="analytics-website">
                    {websiteData.map((card) => {
                      return (
                        <AnalyticsCard
                          key={card.id}
                          // className="analytics-clarion-audio"
                          color={card.color}
                        >
                          <CountStyles color={card.color}>
                            {card.dataCount}{" "}
                          </CountStyles>
                          <div className="analytics-type">
                            {card.name.toUpperCase()}
                          </div>
                          <p>{card.description}</p>
                        </AnalyticsCard>
                      );
                    })}
                  </div>
                  <Line options={options} data={websiteGraphData} />
                </div>
                {/* <div style={{ padding: "60px 0 20px 20px" }}>
                  <Bar options={options} data={ecommerceGraphData} />
                </div> */}

                {/* Traffic Acquisition Analytics */}
                <div style={{ padding: "60px 0 20px 20px" }}>
                  <h2 style={{marginBottom:"20px"}}>Traffic Acquisition</h2>
                  <Bar options={options} data={trafficAnalyticsGraphData} />
                </div>

                {/* E-commerce Analytics */}
                <div style={{ padding: "60px 0 20px 20px" }}>
                  <h2> E-commerce Analytics</h2>
                  <div className="analytics-website">
                    {ecommerceData?.map((card) => {
                      return (
                        <AnalyticsCard key={card.id} color={card.color}>
                          <CountStyles color={card.color}>
                            {card.dataCount}
                          </CountStyles>
                          <div className="analytics-type">
                            {card.name.toUpperCase()}
                          </div>
                          <p>{card.description}</p>
                        </AnalyticsCard>
                      );
                    })}
                  </div>
                  <Line options={options} data={ecommerceGraphData} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Analytics;

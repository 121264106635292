import Sidebar from "../Layout/Sidebar"
import Footer from "../Layout/Footer"
import Header from "../Layout/Header"
import FormCheckInput from "react-bootstrap/esm/FormCheckInput"
import { getParentCategoriesList } from "../../api/category"
import { getAllChildrencategoriesList } from "../../api/coupon"
import { useEffect, useState } from "react";
import Multiselect from 'multiselect-react-dropdown';
function CouponAddAndEditForm() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedParentServices, setSelectedParentServices] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [children, setChildren] = useState([])
    const [options, setOptions] = useState([]);
    useEffect(()=>{
        getParentCategories()
    }, [])
    useEffect(()=>{
        getAllChildrencategories()
    }, [selectedParentServices])
    // const [isOpen, setIsOpen] = useState(false);
    // const [selectedOptions, setSelectedOptions] = useState([]);
    
    const onSelect = (selectedList, selectedItem) => {
        console.log("Selected:", selectedItem);
        setSelectedParentServices([...selectedList]);
        console.log(selectedList)
    };
    const onRemove = (selectedList, removedItem) => {
        console.log("Removed:", removedItem);
        setSelectedParentServices([...selectedList]);
        console.log(selectedList);
    };

    const getParentCategories = async () => { //actually servcies
        let res = await getParentCategoriesList();
        if (res.status === 1) {
            setOptions(res.payload);
        }
    }
    const getAllChildrencategories = async () => { //actually servcies
        let res = await getAllChildrencategoriesList({payload:selectedParentServices});
        console.log("res ast line 44 ", res)
        if (res.status && res.status === 1) {
            setChildren(res.payload);
        }
    }
    return (
        <div id="layout-wrapper">
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        {/* <h4 className="mb-sm-0">{Title} Service</h4> */}
                                        <h4 className="mb-sm-0"> Coupon</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item"> Coupon</li>
                                                {/* <li className="breadcrumb-item active">{Title} Service</li> */}
                                                <li className="breadcrumb-item active"> Service</li>

                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className='row'>
                                                {/* <form 
                                                onSubmit={handleSubmit}> */}
                                                <form
                                                >
                                                    {/* <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="mb-3 required_field">{errors?.submit}</div>
                                                            </div>
                                                        </div> */}
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Parent Services</label>
                                                                {/* <select id="parentCategory" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value={0} key={0}>Horizontal Category</option>
                                                                </select> */}
                                                                {/* <DropdownWithCheckboxes isOpen={isOpen} setIsOpen={setIsOpen} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} options={options} setOptions={setOptions}/> */}
                                                                <Multiselect
                                                                    options={options} // Options to display in the dropdown
                                                                    onSelect={onSelect} // Function will trigger on select event
                                                                    onRemove={onRemove} // Function will trigger on remove event
                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                    placeholder=" Select Parent Services"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Children Services</label>
                                                                {/* <select id="parentCategory" className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value={0} key={0}>Horizontal Category</option>
                                                                </select> */}
                                                                {/* <DropdownWithCheckboxes isOpen={isOpen} setIsOpen={setIsOpen} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} options={options} setOptions={setOptions}/> */}
                                                                <Multiselect
                                                                    options={children} // Options to display in the dropdown
                                                                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                                                                    //onSelect={}  Function will trigger on select event
                                                                    //onRemove={}  Function will trigger on remove event
                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                    placeholder="Select Children Services"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!--end row--> */}
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="mb-2">
                                                                <label className="form-label mb-0">Coupon Name
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="Coupon Name" id="name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-2">
                                                                <label className="form-label mb-0">Minimum Order Value
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="Minimum Order Value" id="name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-2">
                                                                <label className="form-label mb-0">Coupon Type
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="Coupon Type" id="name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-2">
                                                                <label className="form-label mb-0">Applicable On Order Count
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="Applicable On Order Count" id="name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-2">
                                                                <label className="form-label mb-0">Coupon Value
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="In INR" id="name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-2">
                                                                <label className="form-label mb-0">Coupon Discount Cap
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="In INR" id="name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-2">
                                                                <label className="form-label mb-0">Valid From
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="date" className="form-control" placeholder="" id="name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-2">
                                                                <label className="form-label mb-0">Valid Upto
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="date" className="form-control" placeholder="" id="name" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!--end row--> */}
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">User Limit <i>(Use 0 for unlimited)</i>
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="" id="name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Use Limit <i>(Use 0 for unlimited)</i>
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="" id="name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Coupon Status
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                {/* <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('status', e.target.value)} > */}
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" >

                                                                    {/* <option value={0} key={0} selected={form?.status == 0 ? true : false}>Inactive</option> */}
                                                                    <option value={0} key={0}>Inactive</option>
                                                                    {/* <option value={1} key={1} selected={form?.status == 1 ? true : false}>Active</option> */}
                                                                    <option value={1} key={1}>Active</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!--end row--> */}
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Coupon Description
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <textarea type="text" className="form-control" rows={3} placeholder="" id="name" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!--end row--> */}
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Show coupon on checkout
                                                                    <span className="required_field"></span>
                                                                </label>
                                                                <input type="checkbox" className="flex flex-row" placeholder="" id="name" />
                                                                {/* <label className="form-label mb-0 pl-2">checkbox</label> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!--end row--> */}
                                                    <div className="row text-end d-inline">
                                                        <div className="col-md-12">
                                                            {/* <button type="button" className="btn btn-primary mx-2" id="submitButton" onClick={handleSubmit}>Submit</button> */}
                                                            <button type="button" className="btn btn-primary mx-2" id="submitButton" >Submit</button>
                                                            {/* <button type="button" className="btn btn-danger mx-2" id="cancelButton" onClick={handleCancel}>Cancel</button> */}
                                                            <button type="button" className="btn btn-danger mx-2" id="cancelButton" >Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CouponAddAndEditForm
import React, { useState, useEffect } from 'react';
import { defaultPage } from '../../Common/Constant';
import { categoryList, categoryDetails, categoryDelete, categoryUpdate, categoryUpdateStatus } from '../../api/category';
import { couponList, couponDelete, couponUpdateStatus } from '../../api/coupon';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import { useNavigate } from 'react-router-dom';

function CouponListComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = " Coupon List - YKZini";
  },);
  const [searchKeyword, setSearchKeyword] = useState();
  const [lists, setLists] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState(0);
  useEffect(() => {
    getList();
  }, [page]);
  const getList = async () => {
    let res = await couponList({ page: page, recPerPage: 2, searchKeyword: searchKeyword });
    if (res.status === 1) {
      setLists(res.payload)
      setPageInfo(res.paginationInfo)
    } else {
      setLists([])
    }
  }
  const updateOrSetStatus = async (id, status) => {
    // console.log("id ",  id, " status ", status)
    status = status === 0 ? 1 : 0;
    let res = await couponUpdateStatus({ id: id, status: status });
    if (res && res.status === 1) {
      getList();
    }
  }
  const deleteRow = async (id) => {
    let res = await couponDelete({ id: id })
    if (res && res.status === 1) {
      getList()
    }
  }
  const changePage = (pageInfo) => {
    // setPageInfo(pageInfo)
    setPage(page)

  }

  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Coupon List</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item"><a >Home</a></li>
                        <li className="breadcrumb-item">Coupon</li>
                        <li className="breadcrumb-item active">Coupon List</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5 page-title-box">
                <div className="col-xl-12 col-lg-12 d-flex justify-content-between align-items-center mt-2 mb-2">
                  <div className="form-group">
                    <button className="btn btn-primary" onClick={() => navigate('add')}>
                      <i className="ri-upload-line"></i> Bulk Upload
                    </button>
                  </div>
                  <div className="form-group" style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" onClick={() => navigate('add')}>
                      <i className="ri-add-line"></i> Add Coupon
                    </button>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              <div className="row mt-2">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    {/* <!-- end card header --> */}
                    <div className="card-body">
                      <table className="table table-striped table-hover table_client">
                        <thead>
                          <tr className="bg-primary" style={{ color: "white" }}>
                            <th scope="col">Sr. No.</th>
                            <th scope="col" style={{ textAlign: "left" }}>Coupon</th>
                            <th scope="col" style={{ textAlign: "left" }}>Coupon Value</th>
                            <th scope="col" style={{ textAlign: "left" }}>Discount Cap</th>
                            <th scope="col" style={{ textAlign: "left" }}>Code Type</th>
                            <th scope="col" style={{ textAlign: "left" }}>Valid From</th>
                            <th scope="col" style={{ textAlign: "left" }}>Valid Upto</th>
                            <th scope="col" style={{ textAlign: "left" }}>Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {lists ? (<>
                            {lists?.length > 0 ? (<>
                              {lists?.map((row, i) => {
                                return (<>
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td style={{ textAlign: "left" }}>
                                      <strong><span style={{ textAlign: "left" }}>{row?.couponCode}</span></strong>
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {row?.discVal ? row.discVal : "-"}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {row?.discCap ? row?.discCap : "-"}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {row?.codeType ? row?.codeType : "-"}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {row?.validFrom ? row?.validFrom : "-"}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {row?.validUpTO ? row?.validUpTO : "-"}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      <div className="form-check form-switch">
                                        {/* <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} onChange={()=>updateOrSetStatus(row.ID, row.status)} /> */}
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} onChange={()=>updateOrSetStatus(row.uniqueId, row.status)}/>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="p-2 font-16" title="Edit blog category">
                                        <i className="ri-edit-2-line pointerC" onClick={() => navigate('edit?id=' + row.uniqueId, { state: row.uniqueId })}></i>
                                      </span>
                                      <span className="p-2 font-16" title="Delete blog category">
                                        <i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteRow(row?.uniqueId)}></i>
                                      </span>
                                    </td>
                                  </tr>
                                </>)
                              })}
                            </>) : (<>
                              <tr>
                                <td colSpan={5}>No service found</td>
                              </tr>
                            </>)}
                          </>) : (<>

                          </>)}

                        </tbody>
                      </table>
                      <Pagination pagination={pageInfo} changePage={changePage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CouponListComponent
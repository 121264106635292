import React from "react";
import { NavLink } from "react-router-dom";
import { useBetween } from "use-between";
import useShareableState from "../../Common/useShareableState";

export function Sidebar() {
  const pathName = window.location.pathname;
  const { modulesList } = useBetween(useShareableState);
  // console.log("modulesList is ", modulesList)
  const splittedPathArr = pathName.split("/");
  const newPathArr = splittedPathArr.splice(1);
  const splittedPathName = newPathArr.join("/");

  let analyticsExpMenusSlug = [
    "analytics/website",
    "analytics/ecommerce",
    "analytics/traffic-aquisition"
  ];
  
  let uamExpMenusSlug = [
    "access-management-roles",
    "access-management-modules",
    "access-management-users",
  ];

  const getHrefForModule = (slug) => {
    if (slug === "analytics") {
      return "analyticsSidebarControl";
    } else if (slug === "access-management") {
      return "UAMSidebarControl";
    }
    // else if(slug === "erp"){
    //   return "analyticsSidebarControl";
    // }
  };

  const getSubModules = (slug) => {
    if (slug === "analytics") {
      return analyticsExpMenusSlug.includes(splittedPathName) ? "true" : "false";
    } else if (slug === "access-management") {
      return uamExpMenusSlug.includes(splittedPathName) ? "true" : "false";
    }
  };

  const getShowHideDropdown = (slug) => {
    if (slug === "analytics") {
      return analyticsExpMenusSlug.includes(splittedPathName)
        ? "collapse menu-dropdown show"
        : "collapse menu-dropdown";
    } else if (slug === "access-management") {
      return uamExpMenusSlug.includes(splittedPathName)
        ? "collapse menu-dropdown show"
        : "collapse menu-dropdown";
    }
  };

  const filteredModules = modulesList?.filter((module) =>
    ["dashboard", "analytics", "access-management"].includes(module.slug)
  );

  return (
    <div id="layout-wrapper">
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <NavLink to={"/"} className="logo logo-dark">
            <span className="logo-sm">
              <img
                src="/assets/images/ykzini-logo-01.jpg"
                alt="YKzini Logo"
                style={{ width: "100%", height: "60px" }}
              />
            </span>
            <span className="logo-lg">
              <img
                src="/assets/images/ykzini-logo-01.jpg"
                alt="YKzini Logo"
                style={{ width: "100%", height: "60px" }}
              />
            </span>
          </NavLink>
          <NavLink to={"/"} className="logo logo-light">
            <span className="logo-sm">
              <img
                src="/assets/images/ykzini-logo-01.jpg"
                alt="YKzini Logo"
                style={{ width: "100%", height: "60px" }}
              />
            </span>
            <span className="logo-lg">
              <img
                src="/assets/images/ykzini-logo-01.jpg"
                alt="YKzini Logo"
                style={{ width: "100%", height: "60px" }}
              />
            </span>
          </NavLink>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        <aside className="scrool_s">
          <div className="scrool">
            <ul className="navbar-nav">
              {modulesList?.map((module) => {
                if (module?.child?.length === 0) {
                  return (
                    <li className="nav-item" key={module?.ID}>
                      <NavLink to={module?.path} className="nav-link menu-link">
                        <i className={module?.icon}></i> {module?.name}
                      </NavLink>
                    </li>
                  );
                } else {
                  return (
                    <li className="nav-item" key={module?.ID}>
                      <a
                        className="nav-link menu-link"
                        href={`#${getHrefForModule(module?.slug)}`}
                        data-bs-toggle="collapse"
                        role="button"
                        aria-controls={getHrefForModule(module?.slug)}
                        aria-expanded={getSubModules(module?.slug)}
                      >
                        <i className={module?.icon}></i> {module?.name}
                      </a>
                      <div
                        className={getShowHideDropdown(module?.slug)}
                        id={getHrefForModule(module?.slug)}
                      >
                        <ul className="nav nav-sm flex-column">
                          {module?.child?.map((item) => (
                            <li className="nav-item" key={item?.ID}>
                              <NavLink to={item?.path} className="nav-link">
                                {item?.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </aside>
        <div className="sidebar-background"></div>
      </div>
    </div>
  );
}

export default Sidebar;


import './App.css'
import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
import LoginPage from './component/LoginPage';
import Dashboard from './component/Dashboard';
import PageNotFound from './component/PageNotFound';
import Analytics from './component/Analytics';
import '../src/index.css'

import MenuList from './component/Web/menu.list';
import MenuAdd from './component/Web/menu.add';

import BlogCategoryList from './component/Web/blogCategory.list';
import BlogCategoryAdd from './component/Web/blogCategory.add';

import BlogList from './component/Web/blog.list';
import BlogAdd from './component/Web/blog.add';

import PageList from './component/Web/page.list';
import PageAdd from './component/Web/page.add';
import AccessManagementModule from './component/AccessManagement/Modules/AccessManagementModule';
import AccessManagementRole from './component/AccessManagement/Roles/AccessManagementRole';
import AccessManagementUser from './component/AccessManagement/Users';
import { getModules } from './api/auth';
import { useBetween } from 'use-between';
import useShareableState from './Common/useShareableState'
import ForgotPasswordPage from './component/ForgotPasswordPage';
import WebsiteAnalyticsPage from './component/WebsiteAnalyticsPage'
import CategoryListComponent from './component/ERP/categoryList';
import CategoryAddComponent from './component/ERP/category.add';
import CategoryActualListComponent from './component/ERP/category-actualList';
import CategoryActualAddComponent from './component/ERP/categoryActual.add';
import TagListComponent from './component/ERP/tagList';
import TagAddComponent from './component/ERP/tag.add';
// WEB - END

import AppointmentListComponent from './component/CRM/appointmentList';
import CouponAddAndEditForm from './component/Coupon/coupon.form';

import CouponListComponent from './component/Coupon/couponList';
function App() {

  const { setModulesList } = useBetween(useShareableState);

  const getModuleDetails = async () => {
    let res = await getModules();
    if (res?.status) {
      setModulesList(res?.payload);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      getModuleDetails();
    }
  }, [])

  return (
    <div className="App">
      <>
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/analytics/website' element={<WebsiteAnalyticsPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />

          <Route path='/web/menu' element={<MenuList />} />
          <Route path='/web/menu/add' element={<MenuAdd />} />
          <Route path='/web/menu/edit' element={<MenuAdd />} />

          <Route path='/web/blog-category' element={<BlogCategoryList />} />
          <Route path='/web/blog-category/add' element={<BlogCategoryAdd />} />
          <Route path='/web/blog-category/edit' element={<BlogCategoryAdd />} />


          <Route path='/web/blog' element={<BlogList />} />
          <Route path='/web/blog/add' element={<BlogAdd />} />
          <Route path='/web/blog/edit' element={<BlogAdd />} />

          <Route path='/web/page' element={<PageList />} />
          <Route path='/web/page/add' element={<PageAdd />} />
          <Route path='/web/page/edit' element={<PageAdd />} />
          {/* WEB - END */}

          {/* ERP - START */}
          {/* services */}
          <Route path='/services/list' element={<CategoryListComponent />} />
          <Route path='/services/list/add' element={<CategoryAddComponent />} />
          <Route path='/services/list/edit?' element={<CategoryAddComponent />} />
          {/* tags */}
          <Route path='/tag/list' element={<TagListComponent />} />
          <Route path='/tag/list/add' element={<TagAddComponent />} />
          <Route path='/tag/list/edit?' element={<TagAddComponent />} />
          {/* category */}
          <Route path='/category/list' element={<CategoryActualListComponent />} />
          <Route path='/category/list/add' element={<CategoryActualAddComponent />} />
          <Route path='/category/list/edit?' element={<CategoryActualAddComponent />} />
          {/* ERP - END */}

          {/* CRM - START */}
          <Route path='/appointment/list' element={<AppointmentListComponent />} />
          
          <Route path='/access-management-roles' element={<AccessManagementRole />} />
          <Route path='/access-management-modules' element={<AccessManagementModule />} />
          <Route path='/access-management-users' element={<AccessManagementUser />} />

          <Route path='/coupon/list' element={<CouponListComponent/>}/>
          <Route path='/coupon/list/add' element={<CouponAddAndEditForm/>}/>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </>
    </div>
  );
}

export default App;

// import './App.css';
// import React, { useEffect } from 'react';
// import { Routes, Route } from 'react-router-dom';
// import LoginPage from './component/LoginPage';
// import Dashboard from './component/Dashboard';
// import WebsiteAnalyticsPage from './component/WebsiteAnalyticsPage';
// import TrafficAquisitionAnalyticsPage from './component/TrafficAquisitionAnalyticsPage';
// import EcommerceAnalyticsPage from './component/EcommerceAnalyticsPage';
// import PageNotFound from './component/PageNotFound';

// import MenuList from './component/Web/menu.list';
// import MenuAdd from './component/Web/menu.add';

// import BlogCategoryList from './component/Web/blogCategory.list';
// import BlogCategoryAdd from './component/Web/blogCategory.add';

// import BlogList from './component/Web/blog.list';
// import BlogAdd from './component/Web/blog.add';

// import PageList from './component/Web/page.list';
// import PageAdd from './component/Web/page.add';
// import AccessManagementModule from './component/AccessManagement/Modules/AccessManagementModule';
// import AccessManagementRole from './component/AccessManagement/Roles/AccessManagementRole';
// import AccessManagementUser from './component/AccessManagement/Users';
// import { getModules } from './api/auth';
// import { useBetween } from 'use-between';
// import useShareableState from './Common/useShareableState';
// import ForgotPasswordPage from './component/ForgotPasswordPage';


// function App() {
//   const { setModulesList } = useBetween(useShareableState);

//   const getModuleDetails = async () => {
//     let res = await getModules();
//     if (res?.status) {
//       setModulesList(res?.payload);
//     }
//   };

//   useEffect(() => {
//     if (localStorage.getItem('token')) {
//       getModuleDetails();
//     }
//   }, []);

//   return (
//     <div className="App">
//       <Routes>
//         <Route path='/' element={<LoginPage />} />
//         <Route path='/dashboard' element={<Dashboard />} />
//         {/* <Route path='/analytics' element={<Analytics />} /> */}
//         <Route path='/analytics/website' element={<WebsiteAnalyticsPage />} />
//         <Route path='/analytics/traffic-aquisition' element={<TrafficAquisitionAnalyticsPage />} />
//         <Route path='/analytics/ecommerce' element={<EcommerceAnalyticsPage />} />

//         <Route path='/forgot-password' element={<ForgotPasswordPage />} />

//         <Route path='/web/menu' element={<MenuList />} />
//         <Route path='/web/menu/add' element={<MenuAdd />} />
//         <Route path='/web/menu/edit' element={<MenuAdd />} />

//         <Route path='/web/blog-category' element={<BlogCategoryList />} />
//         <Route path='/web/blog-category/add' element={<BlogCategoryAdd />} />
//         <Route path='/web/blog-category/edit' element={<BlogCategoryAdd />} />

//         <Route path='/web/blog' element={<BlogList />} />
//         <Route path='/web/blog/add' element={<BlogAdd />} />
//         <Route path='/web/blog/edit' element={<BlogAdd />} />

//         <Route path='/web/page' element={<PageList />} />
//         <Route path='/web/page/add' element={<PageAdd />} />
//         <Route path='/web/page/edit' element={<PageAdd />} />

//         <Route path='/access-management-roles' element={<AccessManagementRole />} />
//         <Route path='/access-management-modules' element={<AccessManagementModule />} />
//         <Route path='/access-management-users' element={<AccessManagementUser />} />

//         <Route path='*' element={<PageNotFound />} />
//       </Routes>
//     </div>
//   );
// }

// export default App;

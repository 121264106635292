import { get, postAPI, putAPI, deleteAPI } from "../services/api.services";
import Swal from "sweetalert2";
const { defaultPage } = require('../Common/Constant');

export const appointmentList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        records: recPerPage,
        q: searchKeyword
    }
    let res = await postAPI('/user/getBookAppointment', data)
    return res;
}

export const appointmentDetails = async({payload}) => {
    let res = await get('/user/getAppointmentDetails/'+payload);
    return res;
}

export const appointmentUpdateStatus = async({id, status}) => {
    let res = await putAPI('/user/update-appointment-status/'+id+'/'+status)
    return res
}
    

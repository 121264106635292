import React from 'react';
import { useEffect } from "react";
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import useShareableState from '../Common/useShareableState';
import { useBetween } from 'use-between';
import { getModules } from '../api/auth';

function Dashboard() {
    const { modulesList, setModulesList } = useBetween(useShareableState);
    // const fetchModules = async () => {
    //     const modules = await getModules();
    // }
    // useEffect(() => {
    //     document.title = "Dashboard - YKZini"
    //     // fetchModules()
    // }, []);
    return (
        <div>
            <Header />
            <Sidebar/>
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row text-center mt-5">
                                <div className="col-md-12">
                                    <img src="/assets/images/dashboard.png" alt="Dashboard" className="img-fluid w-25" />
                                    <h2 className='mt-5'>Welcome to YKZini</h2>
                                </div>
                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Dashboard;
// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { postAPI } from "../services/api.services";

// const LoginPage = () => {
//   const navigate = useNavigate();

//   const [email, setEmail] = useState();
//   const [password, setPassword] = useState();
//   const [passwordType, setPasswordType] = useState(true);
//   const [keepLoggedIn, setKeepLoggedIn] = useState(0);
//   const [emailError, setEmailError] = useState();
//   const [passwordError, setPasswordError] = useState();
//   const [error, setError] = useState();

//   useEffect(() => {
//     if (localStorage.getItem("token")) {
//       navigate("/dashboard");
//     }
//   }, [navigate]);

//   const handleData = (e) => {
//     let value = e.target.value;
//     let name = e.target.name;

//     switch (name) {
//       case "username":
//         setEmail(value);
//         break;
//       case "password":
//         setPassword(value);
//         break;
//       default:
//         break;
//     }
//   };

//   const onUserLogin = async () => {
//     setError();
//     if (!email) {
//       setEmailError(" *Required");
//       return;
//     }
//     if (!password) {
//       setPasswordError(" *Required");
//       return;
//     }
//     let data = {
//       email: email,
//       password: password,
//       keepLoggedIn: keepLoggedIn ? 1 : 0,
//     };
//     let res = await postAPI("/auth/login/", data);
//     if (res.status === 1) {
//       localStorage.setItem("token", res.payload.token);
//       localStorage.setItem("userDetail", JSON.stringify(res.payload));
//       navigate("/dashboard");
//     } else {
//       setError(res.message);
//     }
//   };

//   useEffect(() => {
//     document.title = "Sign In | YKZini - Admin";
//   }, []);

//   return (
//     <>
//       <div className="auth-page-wrapper pt-5">
//         <div className="auth-page-content">
//           <div className="container">
//             <div className="row justify-content-center">
//               <div className="col-md-8 col-lg-6 col-xl-5">
//                 <div className="card mt-4">
//                   <div className="card-body p-4">
//                     <div className="text-center mt-2">
//                       <div className="mb-2">
//                         <a href="/" className="d-inline-block auth-logo">
//                           <img
//                             src="/assets/images/ykzini-logo-01.jpg"
//                             alt="YKZini Logo"
//                             style={{ width: "100%", height: "120px" }}
//                           />
//                         </a>
//                       </div>
//                     </div>
//                     <div className="p-2 mt-4">
//                       <p className="errorMessage">{error}</p>
//                       <div className="mb-3">
//                         <label htmlFor="username" className="form-label">
//                           Username{" "}
//                           <span className="errorMessage">{emailError}</span>
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control"
//                           id="username"
//                           name="username"
//                           placeholder="Enter username"
//                           value={email}
//                           onChange={(e) => handleData(e)}
//                         />
//                       </div>

//                       <div className="mb-3">
//                         <label className="form-label" htmlFor="password-input">
//                           Password{" "}
//                           <span className="errorMessage">{passwordError}</span>
//                         </label>
//                         <div className="position-relative auth-pass-inputgroup mb-3">
//                           <input
//                             type={passwordType ? "password" : "text"}
//                             className="form-control pe-5 password-input"
//                             placeholder="Enter password"
//                             name="password"
//                             id="password-input"
//                             value={password}
//                             onChange={(e) => handleData(e)}
//                           />
//                           <button
//                             className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
//                             type="button"
//                             id="password-addon"
//                             onClick={() => setPasswordType(!passwordType)}
//                           >
//                             <i className="ri-eye-fill align-middle"></i>
//                           </button>
//                         </div>
//                       </div>

//                       <div className="form-check">
//                         <input
//                           className="form-check-input"
//                           type="checkbox"
//                           value=""
//                           id="auth-remember-check"
//                           onChange={() => setKeepLoggedIn(!keepLoggedIn)}
//                         />
//                         <label
//                           className="form-check-label"
//                           htmlFor="auth-remember-check"
//                         >
//                           {" "}
//                           Keep me logged in
//                         </label>
//                       </div>

//                       <div className="mt-4">
//                         <button
//                           className="btn btn-primary w-100"
//                           style={{ backgroundColor: "#d23f39", border: "none" }}
//                           onClick={() => onUserLogin()}
//                         >
//                           Submit
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <footer className="footer">
//           <div className="container">
//             <div className="row">
//               <div className="col-lg-12">
//                 <div className="text-center">
//                   <p className="mb-0 text-muted">
//                     &copy; YKZini {new Date().getFullYear()}. All rights
//                     reserved. <i className="mdi mdi-heart text-danger"></i>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </footer>
//       </div>
//     </>
//   );
// };

// export default LoginPage;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postAPI } from "../services/api.services";

const LoginPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState(true);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState(localStorage.getItem('token'))
  useEffect(() => {
    document.title = "Sign In | YKZini - Admin";
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }else{
      console.log("no token found")
    }
  }, [navigate, token]);

  const handleData = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    switch (name) {
      case "username":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const onUserLogin = async () => {
    setError("");
    if (!email) {
      setEmailError(" *Required");
      return;
    }
    if (!password) {
      setPasswordError(" *Required");
      return;
    }
    let data = {
      username: email,
      password: password,
      keepLoggedIn: keepLoggedIn ? 1 : 0,
    };
    let res = await postAPI("/auth/adminSignin/", data);
    if (res.status === 1) {
      localStorage.setItem("token", res.payload.token);
      localStorage.setItem("userDetail", JSON.stringify(res.payload));
      navigate("/dashboard");
    } else {
      setError(res.message);
    }
  };

  // useEffect(() => {
  //   document.title = "Sign In | YKZini - Admin";
  // }, []);

  return (
    <>
      <div className="auth-page-wrapper pt-5">
        <div className="auth-page-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card mt-4">
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <div className="mb-2">
                        <a href="/" className="d-inline-block auth-logo">
                          <img
                            src="/assets/images/ykzini-logo-01.jpg"
                            alt="YKZini Logo"
                            style={{ width: "100%", height: "90px" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="p-2 mt-4">
                      <p className="errorMessage">{error}</p>
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                          Username{" "}
                          <span className="errorMessage">{emailError}</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          name="username"
                          placeholder="Enter username"
                          value={email}
                          onChange={(e) => handleData(e)}
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label" htmlFor="password-input">
                          Password{" "}
                          <span className="errorMessage">{passwordError}</span>
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            type={passwordType ? "password" : "text"}
                            className="form-control pe-5 password-input"
                            placeholder="Enter password"
                            name="password"
                            id="password-input"
                            value={password}
                            onChange={(e) => handleData(e)}
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                            onClick={() => setPasswordType(!passwordType)}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="auth-remember-check"
                          onChange={() => setKeepLoggedIn(!keepLoggedIn)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="auth-remember-check"
                        >
                          {" "}
                          Keep me logged in
                        </label>
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary w-100"
                          style={{ backgroundColor: "#f05323", border: "none" }}
                          onClick={() => onUserLogin()}
                        >
                          Submit
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <a href="/forgot-password" className="text-muted">
                          Forgot your password?
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0 text-muted">
                    &copy; YKZini {new Date().getFullYear()}. All rights
                    reserved. <i className="mdi mdi-heart text-danger"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LoginPage;

// import React, { useState, useEffect } from "react";
// import { defaultPage } from "../../../Common/Constant";
// import {
//   blogCategoryList,
//   blogCategoryDelete,
//   blogCategoryUpdateStatus,
// } from "../../../api/blog-category";
// import Footer from "../../Layout/Footer";
// import Header from "../../Layout/Header";
// import Sidebar from "../../Layout/Sidebar";
// import Pagination from "../../Layout/Pagination";
// import { useNavigate } from "react-router-dom"; 
// import { useRef } from "react";
// import EditModal from "./EditModal";
// import AddModal from "./AddModal";
// import { modulesList } from "../../../api/accessManagemet";

// function AccessManagementModule() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     document.title = "Access Management Module - YKZini";
//   });
//   const [searchKeyword, setSearchKeyword] = useState();
//   const [lists, setLists] = useState();
//   const [pageInfo, setPageInfo] = useState({});
//   const [errors, setErrors] = useState({});
//   const [moduleName, setModuleName] = useState("");
//   const [show, setShow] = useState(false);

//   useEffect(() => {
//     getList();
//   }, []);

//   const getList = async () => {
//     let res = await modulesList({
//       page: pageInfo.current_page,
//       recPerPage: defaultPage.perPage,
//       searchKeyword: searchKeyword,
//     });
//     if (res.status === 1) {
//       setLists(res.payload);
//       setPageInfo(res.paginationInfo);
//     } else {
//       setLists([]);
//     }
//   };

//   const updateOrSetStatus = async (id, status) => {
//     status = status === 0 ? 1 : 0;
//     let res = await blogCategoryUpdateStatus({ id: id, status: status });
//     if (res && res.status === 1) {
//       getList();
//     }
//   };

//   const deleteRow = async (id) => {
//     let res = await blogCategoryDelete({ id: id });
//     if (res && res.status === 1) {
//       getList();
//     }
//   };

//   const changePage = (p) => {
//     pageInfo.current_page = p;
//     getList();
//   };

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
  

//   const handleEdit = () => {
//     setShow(true);
//   };

//   const ref = useRef();

//   useEffect(() => {
//     const checkIfClickedOutside = (e) => {
//       if (ref.current && !ref.current.contains(e.target)) {
//         setShow(false);
//       }
//     };
//     document.addEventListener("click", checkIfClickedOutside);
//     return () => {
//       document.removeEventListener("click", checkIfClickedOutside);
//     };
//   }, [show]);


//   function updateModule(id, newName) {
//     const updatedList = lists.map((listItem) => {
//       if (id == listItem.id) {
//         return { ...listItem, name: newName };
//       }
//       return listItem;
//     });
//     setLists(updatedList);
//   }

//   function deleteRole(id) {
//     setLists(
//       lists.filter((listItem) => {
//         if (id !== listItem.id) {
//           return listItem;
//         }
//       })
//     );
//   }
//   return (
//     <div>
//       <Sidebar />
//       <Header />
//       <div id="layout-wrapper">
//         <div className="main-content">
//           <div className="page-content">
//             <div className="container-fluid">
//               <div className="row">
//                 <div className="col-12">
//                   <div className="page-title-box d-sm-flex align-items-center justify-content-between">
//                   <h4 className="mb-sm-0">Modules List</h4>
//                     <div className="page-title-right">
//                       <ol className="breadcrumb m-0">
//                         <li className="breadcrumb-item">
//                           <a>Home</a>
//                         </li>
//                         <li className="breadcrumb-item">Modules</li>
//                       </ol>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="row mb-5 page-title-box d-sm-flex align-items-center justify-content-between">
//                 <div className="col-xl-12 col-lg-12 d-flex justify-content-end mt-2 mb-2">
//                   <div
//                     className="col-2"
//                     style={{ marginLeft: "10px", textAlign: "right" }}
//                   >
//                     <div className="form-group">
//                       {/* <button className="btn btn-primary" onClick={handleShow}>
//                         <i className="ri-add-line"></i> Add Module
//                       </button> */}
//                       <AddModal
//                         show={show}
//                         handleClose={handleClose}
//                         lists={lists}
//                         setLists={setLists}
//                         moduleName={moduleName}
//                         setModuleName={setModuleName}
//                         name="Add Module"
//                         ref={ref}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               {/* <!-- end page title --> */}
//               <div className="row mt-2">
//                 <div className="col-xl-12 col-lg-12">
//                   <div className="card">
//                     {/* <!-- end card header --> */}
//                     <div className="card-body">
//                       <table className="table table-striped table-hover table_client">
//                         <thead>
//                           <tr className="bg-primary" style={{ color: "white" }}>
//                             {/* <th scope="col">Sr. No.</th> */}
//                             <th scope="col" style={{ textAlign: "left" }}>
//                               Module Name
//                             </th>
//                             <th scope="col" style={{ textAlign: "left" }}>
//                               Sub Module Name
//                             </th>
//                             {/* <th scope="col" style={{textAlign:"left"}}>Module</th> */}
//                             {/* <th scope="col">Action</th> */}
//                           </tr>
//                         </thead>

//                         <tbody>
//                           {lists ? (
//                             <>
//                               {lists?.length > 0 ? (
//                                 <>
//                                   {lists?.map((row, i) => {
//                                     return (
//                                       <>
//                                         <tr key={row.ID}>
//                                           {/* <td>{i + 1}</td> */}
//                                           <td style={{ textAlign: "left" }}>
//                                             <strong>
//                                               <span
//                                                 style={{ textAlign: "left" }}
//                                               >
//                                                 {row?.name}
//                                               </span>
//                                             </strong>
//                                           </td>
//                                           <td style={{ textAlign: "left" }}>
//                                             <strong>
//                                               <span
//                                                 style={{ textAlign: "left" }}
//                                               >
//                                                 {row?.parentModuleName ? row?.parentModuleName :'-'}
//                                               </span>
//                                             </strong>
//                                           </td>
//                                           {/* <td>
//                                             <span
//                                               className="p-2 font-16"
//                                               title="Edit blog category"
//                                             >
//                                               <EditModal
//                                                 id={row.id}
//                                                 moduleName={row?.name}
//                                                 updateModule={updateModule}
//                                               />
//                                             </span>
//                                             <span
//                                               className="p-2 font-16"
//                                               title="Delete blog category"
//                                             >
//                                               <i
//                                                 className="ri-delete-bin-2-line del pointerC"
//                                                 onClick={() =>
//                                                   deleteRole(row.id)
//                                                 }
//                                                 // onClick={() =>
//                                                 //   deleteRow(row?.ID)
//                                                 // }
//                                               ></i>
//                                             </span>
//                                           </td> */}
//                                         </tr>
//                                       </>
//                                     );
//                                   })}
//                                 </>
//                               ) : (
//                                 <>
//                                   <tr>
//                                     <td colSpan={5}>No modules found</td>
//                                   </tr>
//                                 </>
//                               )}
//                             </>
//                           ) : (
//                             <></>
//                           )}
//                         </tbody>
//                       </table>
//                       <Pagination
//                         pageInfo={pageInfo}
//                         changePage={changePage}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default AccessManagementModule;

import React, { useState, useEffect, useRef } from "react";
import { defaultPage } from "../../../Common/Constant";
import {
  blogCategoryList,
  blogCategoryDelete,
  blogCategoryUpdateStatus,
} from "../../../api/blog-category";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Pagination from "../../Layout/Pagination";
import { useNavigate } from "react-router-dom"; 
import EditModal from "./EditModal";
import AddModal from "./AddModal";
import { modulesList } from "../../../api/accessManagemet";

function AccessManagementModule() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Access Management Module - YKZini";
  }, []);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [lists, setLists] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [moduleName, setModuleName] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let res = await modulesList({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: searchKeyword,
    });
    if (res.status === 1) {
      setLists(res.payload);
      setPageInfo(res.paginationInfo);
    } else {
      setLists([]);
    }
  };

  const updateOrSetStatus = async (id, status) => {
    status = status === 0 ? 1 : 0;
    let res = await blogCategoryUpdateStatus({ id: id, status: status });
    if (res && res.status === 1) {
      getList();
    }
  };

  const deleteRow = async (id) => {
    let res = await blogCategoryDelete({ id: id });
    if (res && res.status === 1) {
      getList();
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    getList();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEdit = () => {
    setShow(true);
  };

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [show]);

  function updateModule(id, newName) {
    const updatedList = lists.map((listItem) => {
      if (id === listItem.id) {
        return { ...listItem, name: newName };
      }
      return listItem;
    });
    setLists(updatedList);
  }

  function deleteRole(id) {
    setLists(
      lists.filter((listItem) => id !== listItem.id)
    );
  }

  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Modules List</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a href="#">Home</a>
                        </li>
                        <li className="breadcrumb-item">Modules</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5 page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="col-xl-12 col-lg-12 d-flex justify-content-end mt-2 mb-2">
                  <div
                    className="col-2"
                    style={{ marginLeft: "10px", textAlign: "right" }}
                  >
                    <div className="form-group">
                      <AddModal
                        show={show}
                        handleClose={handleClose}
                        lists={lists}
                        setLists={setLists}
                        moduleName={moduleName}
                        setModuleName={setModuleName}
                        name="Add Module"
                        ref={ref}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <table className="table table-striped table-hover table_client">
                        <thead>
                          <tr className="bg-primary" style={{ color: "white" }}>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Module Name
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Sub Module Name
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists ? (
                            lists.length > 0 ? (
                              lists.map((row) => (
                                <tr key={row.ID}>
                                  <td style={{ textAlign: "left" }}>
                                    <strong>
                                      <span style={{ textAlign: "left" }}>
                                        {row?.name}
                                      </span>
                                    </strong>
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    <strong>
                                      <span style={{ textAlign: "left" }}>
                                        {row?.parentModuleName ? row?.parentModuleName : '-'}
                                      </span>
                                    </strong>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5}>No modules found</td>
                              </tr>
                            )
                          ) : null}
                        </tbody>
                      </table>
                      <Pagination pageInfo={pageInfo} changePage={changePage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AccessManagementModule;


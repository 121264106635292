import axios from "axios";
import { apiBaseURL } from "../Common/Constant";
import download from "downloadjs";



export const postAPI = async function (url, data) {
  let _token = localStorage.getItem("token");
  // if(!_token){
  //   _token = data._token
  // }
  const headers = {
    // "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
    source:"web-admin"
  };
  let response;
  try {
    url = apiBaseURL + url;
    console.log(url)
    let requestOptions = {
      method: "POST",
      url: url,
      data: data,
      headers: headers,
    };
    const postApiRes = await axios(requestOptions);
    response = postApiRes.data;
    console.log("response from postAPI is ", response)
    if(response.status === 0){
      if(response.messageCode === 'TOKEN_EXPIRED' || response.messageCode === 'UNAUTHORIZED'){
        console.log("response from postAPI is ", response)
        // localStorage.clear();
        // window.location = '/'
      }
    }
    return response;
  } catch (err) {
    console.log("Error occured in postAPI ", err)
    // return handleError(err);
  }
};

export const get = async function (url, data) {
  let _token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
    source:"web-admin"
  };
  try {
    url = apiBaseURL + url;
    let requestOptions = {
      method: "GET",
      url: url,
      params : data,
      headers: headers,
    };
    const getApiRes = await axios(requestOptions);
    console.log("requestOptions is ", requestOptions)
    console.log("getApiRes is ", getApiRes)
    if(getApiRes.data.status === 0){
      console.log("status 0 in get")
      if(getApiRes.data.messageCode === 'TOKEN_EXPIRED' || getApiRes.data.messageCode === 'UNAUTHORIZED'){
        localStorage.clear();
        window.location = '/'
      }
    }
    return getApiRes.data;
  } catch (err) {
    console.log("ERRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR")
    console.log(err)
    return handleError(err);
  }
};

export const putAPI = async function (url, data) {
  let _token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
    source:"web-admin"
  };
  try {
    url = apiBaseURL + url;
    console.log("url in putAPI ", url)
    let requestOptions = {
      method: "PUT",
      url: url,
      data: data,
      headers: headers,
    };
    console.log("requestOptions before sending put request ", requestOptions)
    const putApiRes = await axios(requestOptions);
    console.log("response in putApiRes  is ", putApiRes)
    if(putApiRes.data.status === 0){
      if(putApiRes.data.messageCode === 'TOKEN_EXPIRED' || putApiRes.data.messageCode === 'UNAUTHORIZED'){
        localStorage.clear();
        window.location = '/'
      }
    }
    return putApiRes.data;
  } catch (err) {
    console.log("error in putAPI ", err)
    // return handleError(err);
  }
};

export const deleteAPI = async function (url, data) {
  let _token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
    source:"web-admin"

  };
  try {
    url = apiBaseURL + url;
    console.log("url in deleteAPI ", url)
    let requestOptions = {
      method: "DELETE",
      url: url,
      data: JSON.stringify(data),
      // params : queryParameter,
      headers: headers,
    };
    const deleteApiRes = await axios(requestOptions);
    if(deleteApiRes.data.status === 0){
      if(deleteApiRes.data.messageCode === 'TOKEN_EXPIRED' || deleteApiRes.data.messageCode === 'UNAUTHORIZED'){
        localStorage.clear();
        window.location = '/'
      }
    }
    return deleteApiRes.data;
  } catch (err) {
    return handleError(err);
  }
};

export const uploadImageAPI = async function (url, fileData) {
  let _token = localStorage.getItem("token");
  var formData = new FormData();
  // console.log('formdata', fileData)
  for (let i = 0; i < fileData.length; i++) {
    formData.append("files", fileData[i]);
  }
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + _token,
  };
  try {
    const postApiRes = await axios({
      method: "POST",
      url: apiBaseURL + url,
      data: formData,
      headers: headers,
    });
    // checkToken(postApiRes.data)
    return postApiRes.data;
  } catch (err) {    
    return handleError(err);
  }
};

export const getDownloadFile = async function (url, fileName=null) {
  let _token = localStorage.getItem("token");
  try {
    fetch(apiBaseURL + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + _token,
      },
    })
    .then(function (resp) {
      return resp.blob();
    })
    .then(function (blob) {
      let flName = Math.floor(new Date().getTime() / 1000);
      if(fileName && fileName !== null){
        flName = fileName
      }
      return download(blob, flName);
    });
  } catch (err) {
    return { data: { error: "Internal Server Error" } };
  }
};

const handleError = (err) =>{
  if(err.response && err.response.data){
    if(err.response.data.messageCode === 'TOKEN_EXPIRED' || err.response.data.messageCode === 'UNAUTHORIZED'){
      localStorage.clear();
      window.location = '/';
    }

    return err.response.data;
  } else {
    let response = {status: 0, message: err.message ? err.message: "Internet issue. Please check your internet connection!", messageCode: err.code ? err.code : ''};
    if(err.code && err.code === 'ERR_NETWORK')
      response.message = "Internet issue. Please check your internet connection!";
      return response;
  }
}
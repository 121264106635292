import { get, postAPI, putAPI, deleteAPI } from "../services/api.services";
import Swal from "sweetalert2";
const { defaultPage } = require('../Common/Constant');

export const couponList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        records: recPerPage,
        q: searchKeyword
    }
    let res = await postAPI('/coupon/getList', data)
    return res;
}

export const couponDetails = async({payload}) => {
    let res = await get('/services/details/'+payload);
    return res;
}

export const couponDelete = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/coupon/deleteCoupon/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const couponUpdateStatus = async({id, status}) => {
    return Swal.fire({
        text: 'Are you sure you want to update?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/coupon/update-status/'+id+'/'+status)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const couponCreate = async({payload}) => {
    let res = await postAPI('/service/add', payload)
    console.log("res at categoryCreate", res)
    if(res == undefined){
        return Swal.fire({
            text:"Category couldn't be created",
            timer: 1500,
        })
    }
    return Swal.fire({
        text:res.message,
        timer: 1500,
    })
    .then(() => {
        return res;
    })
}

export const couponUpdate = async({payload}) => {
    let ID = payload?.id;
    delete payload.id;

    let res = await putAPI('/services/update/'+ID, payload)
    return res;
}

export const getAllChildrencategoriesList = async(data) => {
    let res = await postAPI('/coupon/getAllServices/', data);
    return res;
}

export const couponCheckName = async({name, id = 0}) => {
    let data = {
        name: name,
        id: id
    }
    let res = await postAPI('/category/check-unique', data);
    return res;
}

// export const mappedCategoryAttributeSet = async({id}) => {
//     let res = await get('/category/mapping/'+id);
//     return res;
// }

// export const mappedCategoryAttributes = async({id}) => {
//     let res = await get('/category/mapped-attributes/'+id);
//     return res;
// }

// export const mapCategoryAttributeSet = async({id, attributeSetIDArr}) => {
//     let data = {
//         attributeSetIDArr: attributeSetIDArr
//     };
//     let res = await putAPI('/category/mapping/'+id, data);
//     return res;
// }

// export const getParentCategoriesList = async() => {
//     let res = await get('/get-parent-categories/');
//     return res;
// }
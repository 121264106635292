import React, { useState, useEffect } from "react";
import { defaultPage } from "../../../Common/Constant";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Pagination from "../../Layout/Pagination";

import EditModal from "./EditModal";
import AddModal from "./AddModal";
import { userDelete, usersList } from "../../../api/accessManagemet";

function AccessManagementUser() {
  useEffect(() => {
    document.title = "Access Management Users - YKZini";
  });

  const [searchKeyword, setSearchKeyword] = useState();
  const [lists, setLists] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [uniqueStatus, setUniqueStatus] = useState();

  useEffect(() => {
    getList();
  }, [uniqueStatus]);

  const getList = async () => {
    let res = await usersList({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: searchKeyword,
    });
    if (res.status === 1) {
      setLists(res.payload);
      setPageInfo(res.paginationInfo);
    } else {
      setLists([]);
    }
  };

  const deleteRow = async (id) => {
    let res = await userDelete({ id: id });
    if (res && res.status === 1) {
      getList();
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    getList();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Users List</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a>Home</a>
                        </li>
                        <li className="breadcrumb-item">Users</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5 page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="col-xl-12 col-lg-12 d-flex justify-content-end mt-2 mb-2">
                  <div
                    className="col-2"
                    style={{ marginLeft: "10px", textAlign: "right" }}
                  >
                    <div className="form-group">
                      <button className="btn btn-primary" onClick={handleShow}>
                        <i className="ri-add-line"></i> Add User
                      </button>
                      <AddModal
                        show={show}
                        handleClose={handleClose}
                        name="Add User Details"
                        setUniqueStatus={setUniqueStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              <div className="row mt-2">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    {/* <!-- end card header --> */}
                    <div className="card-body">
                      <table className="table table-striped table-hover table_client">
                        <thead>
                          <tr className="bg-primary" style={{ color: "white" }}>
                            <th scope="col">Sr. No.</th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              User Name
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Phone
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Email address
                            </th>

                            <th scope="col" style={{ textAlign: "left" }}>
                              Status
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {lists ? (
                            <>
                              {lists?.length > 0 ? (
                                <>
                                  {lists?.map((row, i) => {
                                    return (
                                      <tr key={row.ID}>
                                        <td>{i + 1}</td>
                                        <td style={{ textAlign: "left" }}>
                                          {row?.firstName
                                            ? row?.firstName
                                            : "-"}
                                          {row?.lastName
                                            ? " " + row?.lastName
                                            : ""}
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          {row?.phone ? row?.phone : "-"}
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          {row?.email ? row?.email : "-"}
                                        </td>
                                        <td
                                          style={{
                                            textAlign: "left",
                                            textTransform: "capitalize",
                                            color:
                                              row?.statusSlugTxt === "approved"
                                                ? "green"
                                                : "red",
                                          }}
                                        >
                                          {row?.statusTxt
                                            ? row?.statusTxt
                                            : "-"}
                                        </td>
                                        <td>
                                          <span
                                            className="p-2 font-16"
                                            title="Edit User"
                                          >
                                            <EditModal
                                              id={row.ID}
                                              setUniqueStatus={setUniqueStatus}
                                            />
                                          </span>
                                          <span
                                            className="p-2 font-16"
                                            title="Delete User"
                                          >
                                            <i
                                              className="ri-delete-bin-2-line del pointerC"
                                              onClick={() => deleteRow(row?.ID)}
                                            ></i>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td colSpan={12}>No users found</td>
                                  </tr>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      <Pagination
                        pageInfo={pageInfo}
                        changePage={changePage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AccessManagementUser;

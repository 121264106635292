import React, { useState } from 'react';
import { postAPI } from '../services/api.services';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const handleData = (e) => {
        setEmail(e.target.value);
    };

    const onForgotPassword = async () => {
        setError('');
        setMessage('');
        if (!email) {
            setError(" *Email is required");
            return;
        }
        let res = await postAPI('/auth/forgot-password/', { email });
        if (res.status === 1) {
            setMessage("Password reset link has been sent to your email.");
        } else {
            setError(res.message);
        }
    };

    return (
        <div className="auth-page-wrapper pt-5">
            <div className="auth-page-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card mt-4">
                                <div className="card-body p-4">
                                    <div className="text-center mt-2">
                                        <div className='mb-2'>
                                            <a href="/" className="d-inline-block auth-logo">
                                                <h4 style={{color: 'black'}}>Forgot your password</h4>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="p-2 mt-4">
                                        <p className='errorMessage' style={{ color: 'red' }}>{error}</p>
                                        <p className='successMessage' style={{ color: 'green' }}>{message}</p>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email <span className='errorMessage' style={{ color: 'red' }}> {error}</span></label>
                                            <input type="email" className="form-control" id="email" name="email" placeholder="Enter email" value={email} onChange={(e) => handleData(e)} />
                                        </div>

                                        <div className="mt-4">
                                            <button className="btn btn-#003056 w-100" style={{ backgroundColor: "#f05323", border: "none" }} onClick={onForgotPassword}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <p className="mb-0 text-muted">&copy; YKZini {new Date().getFullYear()}. All rights reserved. <i className="mdi mdi-heart text-danger"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ForgotPasswordPage;

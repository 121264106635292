import React, { useState, useEffect, useSearchParams } from 'react';
import { categoryCreate, getParentCategoriesList, categoryDetails, categoryUpdate } from '../../api/category';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactQuill from "react-quill";
import CategoryListComponent from './categoryList';

function CategoryAddComponent(){
    const navigate = useNavigate();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id');
    const Title = id===null?"Add":"Edit" ;
    useEffect(() => {
        document.title = Title +" Services - YKZini";
    },);
    const [form, setForm] = useState({
        'parentID': 0,
        'name': "",
        'priority': 0,
        'status': 0,
        });
    const [errors, setErrors] = useState({});
    const [parentCategories, setParentCategories] = useState([]);
    useEffect(() => {
        getParentCategories()
    }, []);
    const getParentCategories = async () => {
        let res = await getParentCategoriesList();
        if (res.status === 1) {
            setParentCategories(res.payload);
        }
    }
    const getCategoryDetail = async () => {
        if(id !== null && id != 0 && id != ''){

                let res = await categoryDetails({payload:id})
                if(res.status === 1){
                    setForm({
                        ...form,
                        ['parentID']: res.payload.parent.ID,
                        ['name']: res.payload.name,
                        ['priority']: res.payload.priority,
                        ['status']: res.payload.status,
                        ['imageURL']: res.payload.imageURL
                    });
                    console.log(form);
                    console.log(res.payload);
                }
            }
        }
        useEffect(() => {
            if(id !== null && id != 0 && id != ''){
            getCategoryDetail();
            }
        },[id]);
        const setField = (field, value) => {
            setForm({
                ...form,
                [field]: value
            });
            if (!!errors[field]){
                setErrors({
                    ...errors,
                    [field]: null
                });            
            }
        }
        const validateForm = () =>{
            const {name} = form;
            const newErrors = {};
            if(!name || name === '') newErrors.name = 'Please enter this field';
            return newErrors;
        }
        const handleSubmit = async (e) => {
            e.preventDefault();
            setErrors();
            const formErrors = validateForm();
            if(Object.keys(formErrors).length > 0){
                setErrors(formErrors)
            } else {
                
                let obj = {
                    parentID: form.parentID ? form.parentID : 0,
                    name: form.name,
                    priority: form.priority,
                    status: form.status
                }
                let res = {};
                if(id=== null || id == "" || id==0){
                    res = await categoryCreate({payload: obj});
                }else{
                    obj.id = id;
                    res = await categoryUpdate({payload: obj});
                }
                if(res.status == 1){
                    navigate("/services/list");
                }else {
                    setErrors({...errors,['submit']:res.message})
                }
                 
            }
            
        }
        const handleCancel = () => {
            navigate("/services/list");
        }

        return (
            <div>
                <Sidebar />
                <Header />
                <div id="layout-wrapper">
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                            <h4 className="mb-sm-0">{Title} Service</h4>
                                            <div className="page-title-right">
                                                <ol className="breadcrumb m-0">
                                                    <li className="breadcrumb-item"><a >Home</a></li>
                                                    <li className="breadcrumb-item"> Services</li>
                                                    <li className="breadcrumb-item active">{Title} Service</li>
                                                </ol>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end page title --> */}
                                <div className="row mt-2">
                                    <div className="col-xl-12 col-lg-12">
                                        <div className="card">
                                            {/* <!-- end card header --> */}
                                            <div className="card-body">
                                                <div className='row'>
                                                    <form onSubmit={handleSubmit}>
                                                        {/* <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="mb-3 required_field">{errors?.submit}</div>
                                                            </div>
                                                        </div> */}
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="mb-3">
                                                                    <label className="form-label mb-0">Parent Service </label>
                                                                    <select id="parentCategory" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('parentID',e.target.value)} >
                                                                        <option value={0} key={0}>Select Parent Service</option>
                                                                        {parentCategories.map((item, indx) => {
                                                                            return <option value={item.ID} key={indx} selected={item.ID === form?.parentID ? true : false}>{item.name}</option>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3">
                                                                    <label className="form-label mb-0">Service Name 
                                                                        <span className="required_field">*{errors?.name}</span>
                                                                    </label>
                                                                    <input type="text" className="form-control" placeholder="Enter category name" id="name" defaultValue={form?.name} onChange={(e) => setField('name', e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3">
                                                                    <label className="form-label mb-0">Priority</label>
                                                                    <input type="number" className="form-control" placeholder="Enter priority" id="priority" onChange={(e) => setField('priority', e.target.value)} defaultValue={form?.priority}  />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3">
                                                                    <label className="form-label mb-0">Status</label>
                                                                    <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('status',e.target.value)} >
                                                                        <option value={0} key={0} selected = {form?.status == 0?true:false}>Inactive</option>
                                                                        <option value={1} key={1} selected = {form?.status == 1?true:false}>Active</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-4">
                                                                <div className="mb-3">
                                                                    <label className="form-label mb-0">Image (Dimension: 200 X 200 pixels)</label>
                                                                    <input type="file" className="form-control" id="categoryFile" onChange={handleChange} multiple />
                                                                </div>
                                                                <img className="mt-3" id="imageView" src={form?.imageURL ? form?.imageURL : ""} style={{display:'none',height:"50px", border:"2px solid grey"}} />
                                                            </div> */}
                                                        </div>
                                                        {/* <!--end row--> */}
    
                                                        <div className="row text-end d-inline">
                                                            <div className="col-md-12">
                                                                <button type="button" className="btn btn-primary mx-2" id="submitButton" onClick={handleSubmit}>Submit</button>
                                                                <button type="button" className="btn btn-danger mx-2" id="cancelButton" onClick={handleCancel}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
}

export default CategoryAddComponent
import React, { useState, useEffect } from 'react';
import { defaultPage } from '../../Common/Constant';
// import { categoryList, categoryDetails, categoryDelete, categoryUpdate, categoryUpdateStatus } from '../../api/category';
import { appointmentList, appointmentDetails, appointmentUpdateStatus } from '../../api/appointment';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import { useNavigate } from 'react-router-dom';
import AppointmentDetails from './appointmentDetails';

function AppointmentListComponent() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "ERP Appointment List - YKZini";
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [page, setPage] = useState(0)
    const [errors, setErrors] = useState({});
    const [wantsToSeeDetails, setWantsToSeeDetails] = useState(false)
    const [details, setDetails] = useState([])
    useEffect(() => {
        getList();
    }, [page]);

    const getList = async () => {
        let res = await appointmentList({ page: page, recPerPage: 2, searchKeyword: searchKeyword });
        if (res.status === 1) {
            setLists(res.payload)
            setPageInfo(res.paginationInfo)
        } else {
            setLists([])
        }
    }
    const handleSelectChange = async (event, id) => {
        const status = event.target.value
        console.log("id is ", id, " and status is ",event.target.value )
        let res = await appointmentUpdateStatus({id:id,status:status})
        if(res.status == 1){
            getList()
        }
    }
    // const updateOrSetStatus = async (id,status) => {
    //     status = status === 0 ? 1 : 0;
    //     let res = await categoryUpdateStatus({id:id,status:status});
    //     if(res && res.status === 1){
    //         getList();            
    //     }
    // }
    // const deleteRow = async (id) => {
    //     let res = await categoryDelete({id:id})
    //     if(res && res.status === 1){
    //         getList()
    //     }
    // }
    const changePage = (page) => {
        // setPageInfo(pageInfo)
        setPage(page)
    }
    const options = [
        { value: 0, label: "unassigned" },
        { value: 1, label: "assigned" },
        { value: 2, label: "accepted" },
        { value: 3, label: "rejected" },
        { value: 4, label: "cancelled by customer" },
        { value: 5, label: "cancelled by vendor" },
        { value: 6, label: "cancelled by admin" },
        { value: 7, label: "started by vendor" },
        { value: 8, label: "finished by vendor" },
        { value: 9, label: "confirmed by customer" },
        { value: 10, label: "closed" }
    ];
    const getAppointmentDetails = async (id) => {
        console.log("id in getAppointmentDetails ", id)
        let res = await appointmentDetails({ payload: id });
        if (res.status === 1) {
            setDetails(res.payload)
            setWantsToSeeDetails(true)
        } else {
            setDetails([])
        }
    }
    return (
        <div>
            {details.length > 0 ? <AppointmentDetails wantsToSeeDetails={wantsToSeeDetails} setWantsToSeeDetails={setWantsToSeeDetails} details={details} /> : null}
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Appointment List</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Appointments</li>
                                                <li className="breadcrumb-item active">Appointment List</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5 page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="col-xl-12 col-lg-12 d-flex justify-content-end mt-2 mb-2">
                                    <div className="col-2" style={{ marginLeft: "10px", textAlign: "right" }}>
                                        <div className="form-group">
                                            {/* <button className="btn btn-primary" onClick={() => navigate('add')}> <i className="ri-add-line"></i> Add Serivce</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">Sr. No.</th>
                                                        <th scope="col" style={{ textAlign: "left" }}>Vendor Name</th>
                                                        <th scope="col" style={{ textAlign: "left" }}>Customer Name</th>
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Service</th> */}
                                                        <th scope="col" style={{ textAlign: "left" }}>Date</th>
                                                        <th scope="col" style={{ textAlign: "left" }}>Time</th>
                                                        <th scope="col" style={{ textAlign: "left" }}>Payment Amount</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {lists ? (<>
                                                        {lists?.length > 0 ? (<>
                                                            {lists?.map((row, i) => {
                                                                return (<>
                                                                    <tr>
                                                                        <td>{i + 1}</td>
                                                                        <td style={{ textAlign: "left" }}>
                                                                            <strong><span style={{ textAlign: "left" }}>{row?.vendorName ? row?.vendorName : "-"}</span></strong>
                                                                        </td>
                                                                        <td style={{ textAlign: "left" }}>
                                                                            <strong><span style={{ textAlign: "left" }}>{row?.consumerName ? row?.consumerName : "-"}</span></strong>
                                                                        </td>
                                                                        {/* <td style={{textAlign:"left"}}>
                                                                    {row?.serviceName ? row?.serviceName : "-"}
                                                                </td> */}
                                                                        <td style={{ textAlign: "left" }}>
                                                                            {row?.appointmentDate ? row?.appointmentDate : "-"}
                                                                        </td>
                                                                        <td style={{ textAlign: "left" }}>
                                                                            {row?.time ? row?.time : "-"}
                                                                        </td>
                                                                        <td style={{ textAlign: "left" }}>
                                                                            {row?.finalAmount ? row?.finalAmount : "-"}
                                                                        </td>
                                                                        <td style={{ textAlign: "left" }}>
                                                                            <div className="form-check form-switch">
                                                                                {/* <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} onChange={()=>updateOrSetStatus(row.ID, row.status)} /> */}
                                                                                {/* <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} /> */}
                                                                                <select
                                                                                    className="form-select"
                                                                                    id="flexSwitchCheckDefault"
                                                                                    value={row.lastStatus}
                                                                                onChange={(e) => handleSelectChange(e, row.ID)} // Add your event handler here
                                                                                >
                                                                                    {/* {options.map((option) => (
                                                                                        <option key={option.value} value={option.value} {...option.value  == 2? 'selected':""}>
                                                                                            {`${option.value} - ${option.label}`}
                                                                                                 
                                                                                        </option>
                                                                                    ))} */}
                                                                                    {options.map((option) => (
                                                                                        <option key={option.value} value={option.value} 
                                                                                        >
                                                                                            {`${option.value} - ${option.label}`}

                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <button className="btn btn-primary" onClick={() => { getAppointmentDetails(row.ID) }}> View Details</button>
                                                                            {/* <span className="p-2 font-16" title="Edit blog category"> 
                                                                        <i className="ri-edit-2-line pointerC" onClick={() => navigate('edit?id=' + row.ID, { state: row.ID })}></i>
                                                                        <i className="ri-edit-2-line pointerC" ></i>
                                                                    </span>
                                                                    <span className="p-2 font-16" title="Delete blog category">
                                                                        <i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteRow(row?.ID)}></i>
                                                                        <i className="ri-delete-bin-2-line del pointerC" ></i>
                                                                        
                                                                    </span> */}
                                                                        </td>
                                                                    </tr>
                                                                </>)
                                                            })}
                                                        </>) : (<>
                                                            <tr>
                                                                <td colSpan={5}>No service found</td>
                                                            </tr>
                                                        </>)}
                                                    </>) : (<>

                                                    </>)}

                                                </tbody>
                                            </table>
                                            <Pagination pagination={pageInfo} changePage={changePage} />
                                            {/* <Pagination pagination={pageInfo} /> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AppointmentListComponent
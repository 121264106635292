import { get, postAPI, putAPI, deleteAPI } from "../services/api.services";
import Swal from "sweetalert2";
const { defaultPage } = require('../Common/Constant');
export const tagList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        records: recPerPage,
        q: searchKeyword
    }
    let res = await postAPI('/tag/getList', data)
    return res;
}

export const tagDetails = async({payload}) => {
    let res = await get('/tag/details/'+payload);
    return res;
}

export const tagDelete = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/tag/delete/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const tagUpdateStatus = async({id, status}) => {
    return Swal.fire({
        text: 'Are you sure you want to update?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/tag/update-status/'+id+'/'+status)
            console.log("status update ", res)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}
export const tagCreate = async({payload}) => {
    let res = await postAPI('/tag/add', payload)
    // console.log("res at categoryCreate", res)
    if(res == undefined){
        return Swal.fire({
            text:"Category couldn't be created",
            timer: 1500,
        })
    }
    return Swal.fire({
        text:res.message,
        timer: 1500,
    })
    .then(() => {
        return res;
    })
}
export const tagUpdate = async({payload}) => {
    let ID = payload?.id;
    delete payload.id;

    let res = await putAPI('/tag/update/'+ID, payload)
    return res;
}
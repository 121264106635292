import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
function AppointmentDetails({ wantsToSeeDetails, setWantsToSeeDetails, details }) {
  console.log("details in AppointmentDetails component ", details)
  useEffect(() => {
    console.log("appointmentDetails rendered")
  }, [])
  return (
    <>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        zIndex: 5000, // Ensures the box is on top of other elements
        visibility: wantsToSeeDetails ? 'visible' : 'hidden'
        // display: wantsToSeeDetails?'visible':'none'
      }}>
        <div
          style={{
            width: '70%', // Adjust as needed
            //   maxWidth: '600px', // Optional: Maximum width for responsiveness
            height: 'auto', // Adjust as needed
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            color: 'black',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            overflow:'auto'
          }}
        >
          {/* Close button */}
          <button
            onClick={() => setWantsToSeeDetails(false)} // Replace with actual close logic
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: 'transparent',
              border: 'none',
              color: 'black',
              fontSize: '24px',
              cursor: 'pointer',
            }}
          >
            &times; {/* Unicode for multiplication sign, commonly used as a cross */}
          </button>

          {/* Content of the inner box */}
          <h2>Appointment Details</h2>
          {/* <p>Content goes here.</p> */}
          <div style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}>
            <ul
              style={{
                listStyle: 'none',
                width: "30%"
              }}>
              <li> <h4>{details[0].title?details[0].title:null}</h4> </li>
              {details[0].data.map((item) =>
                <li><strong>Service Name : </strong>{item.serviceName}
                  <br />
                  <span>Appointment Date : {item.appointmentDate}</span>
                  <br/>
                  <span>Last Status : {" "}
                    {(() => {
                      switch (item.lastStatus) {
                        case 0:
                          return "unassigned";
                        case 1:
                          return "assigned";
                        case 2:
                          return "accepted";
                          case 3:
                            return "rejected";
                            case 4:
                          return "cancelled by customer";
                          case 5:
                          return "cancelled by vendor";
                          case 6:
                          return "cancelled by admin";
                          case 7:
                          return "started by vendor";
                          case 8:
                          return "finished by vendor";
                          case 9:
                          return "confirmed by customer";
                          case 10:
                          return "closed";
                        default:
                          return "Unknown";
                      }
                    })()}
                  </span>
                  <br/>
                  <span>Status updated on : {item.lastStatusUpdatedOn}</span>
                </li>)}
            </ul>
            <ul
              style={{
                listStyle: 'none',
                width: "30%"
              }}>
              <li> <h4>{details[1].title?details[1].title:null}</h4> </li>
              {details[1].data.map((item) =>
                <li><strong>Service Name : </strong>{item.serviceName}
                  <br />
                  <span>Appointment Date : {item.amountWithoutTaxDis}</span>
                  <br/>
                  <span>Taxable amount : {item.taxAmount}</span>
                  <br/>
                  <span> Discount amount : {item.discountAmount}</span>
                  <br/>
                  <span> Net amount : {item.netAmount}</span>
                </li>)}
                <li><strong>Payable Amount :</strong> {details[1].payableAmountForAllServices}</li>
            </ul>
             <ul
              style={{
                listStyle:'none',
                width:"30%"
              }}>
                <li>  <h4>{details[2].title?details[2].title:null}</h4> </li>
                <li>Name : {details[2].consumserName?details[2].consumserName:null}</li>
                <li>Contact Number: {details[2].consumerMobile?details[2].consumerMobile:null}</li>
                <li>Email: {details[2].consumerEmail?details[2].consumerEmail:null}</li>
                <li>email verified: {details[2].isEmailVerified == 1? "YES":"NO"}</li>
                <li>Contact Number verified: {details[2].isMobileVerified == 1? "YES":"NO"}</li>
              </ul>

              <ul
              style={{
                listStyle:'none',
                width:"30%"
              }}>
                <li>  <h4>{details[3].title?details[3].title:null}</h4> </li>
                <li>Contact Number : {details[3].mobile?details[3].mobile:null}</li>
                <li>Contact Number verified: {details[3].mVerified == 1? "YES":"NO"}</li>
                <li>Govt ID Type: {details[3].vendorIdType?details[3].vendorIdType:null}</li>
                <li>Govt ID details: {details[3].vendorId?details[3].vendorId:null}</li>
              </ul>

             {/* <ul
              style={{
                listStyle:'none',
                width:"30%"
              }}>
                <li> <h4>Vendor Details</h4> </li>
                <li>Mobile: 1234567890</li>
                <li>Verified: 1234567890</li>
                <li>Id: 1234567890</li>
                <li>Aadhaar: 1234567890</li>
              </ul>

              <ul
              style={{
                listStyle:'none',
                width:"30%"
              }}>
                <li> <h4>Vendor Details</h4> </li>
                <li>Mobile: 1234567890</li>
                <li>Verified: 1234567890</li>
                <li>Id: 1234567890</li>
                <li>Aadhaar: 1234567890</li>
              </ul> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default AppointmentDetails